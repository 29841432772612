<template>
  <div class="sf-quantity-selector sf-quantity-selector--pure">
    <SfButton
      :disabled="(disabled && !disabledMax) || disabledMin"
      :class="['sf-quantity-selector__button', {'is-minus': isMinusButtonShown }, {'is-minus--active': qty > minQty}]"
      data-transaction-name="Quantity - Decrement"
      @click="qtyChangeHandler(DECREMENT, 'minus')"
    >
      <slot v-if="isMinusButtonShown" name="minus">
        <span>&minus;</span>
      </slot>
      <slot v-else name="remove">
        <span>&times;</span>
      </slot>
    </SfButton>
    <SfInput
      v-model="value"
      type="text"
      inputmode="decimal"
      v-bind="$attrs"
      :disabled="disabled && !disabledMax"
      :label="measure"
      :focus-by-directive="false"
      data-transaction-name="Quantity - Field"
      :class="['sf-quantity-selector__input', { 'sf-quantity-selector__input--max-length': isMaxInputLength }]"
      @keydown.native="onInputKeydown"
      @focus="onInputFocus"
      @blur="onInputBlur"
    >
      <template #label="{ label }">
        <span class="sf-input__label--hidden">{{ qty }}</span>
        {{ label }}
      </template>
    </SfInput>
    <SfButton
      :disabled="disabledMax"
      class="sf-quantity-selector__button is-plus"
      data-transaction-name="Quantity - Increment"
      @click="qtyChangeHandler(INCREMENT, 'plus')"
    >
      <slot name="plus">
        <span>&plus;</span>
      </slot>
    </SfButton>
    <slot />
  </div>
</template>
<script>
import { SfButton } from '@storefront-ui/vue';
import { roundNumberToPrecision } from 'theme/helpers/number';
import { mapActions } from 'vuex';
import SfInput from 'theme/components/storefront-override/SfInput';
import GoogleTagManager from 'theme/mixins/gtm';

const MIN_QTY = 1;
const DIGITS_AFTER_COMMA = 2;
const InputLength = {
  Min: 1,
  MaxInteger: 3,
  MaxFloat: 5
}
const CONTROL_CODES = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];

export default {
  name: 'SfOQuantitySelector',
  components: {
    SfInput,
    SfButton
  },
  mixins: [
    GoogleTagManager
  ],
  inheritAttrs: false,
  model: {
    prop: 'qty'
  },
  props: {
    /** Quantity */
    qty: {
      type: [Number, String],
      default: 1
    },
    product: {
      type: Object,
      required: true,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledMin: {
      type: Boolean,
      default: false
    },
    measureKg: {
      type: Boolean,
      default: false
    }
  },
  inject: {
    provided: 'provided',
    isMicroCart: {
      from: 'isMicroCart',
      default: false
    },
    isCheckoutCartChanges: {
      from: 'isMicroCart',
      default: false
    }
  },
  data () {
    return {
      inputValue: this.qty || 0,
      focused: false
    }
  },
  computed: {
    value: {
      get () {
        if (this.focused) return this.inputValue;
        return Number.isInteger(+this.qty) ? +this.qty : +this.qty.toFixed(2);
      },
      set (value) {
        this.inputValue = value;
      }
    },
    INCREMENT () {
      return +this.qty + this.step;
    },
    DECREMENT () {
      return +this.qty - this.step;
    },
    isQtyDecimal () {
      return Boolean(this.provided?.isQtyDecimal);
    },
    measure () {
      return this.measureKg
        ? this.$t('kg')
        : this.$t('pc');
    },
    unitStep () {
      return this.provided.unitStep;
    },
    step () {
      return this.isDecimalMeasure ? Number(this.unitStep) : MIN_QTY;
    },
    isDecimalMeasure () {
      return this.isQtyDecimal && Boolean(this.unitStep);
    },
    minQty () {
      return Number(this.provided.minQty) || MIN_QTY;
    },
    maxQty () {
      return this.isDecimalMeasure
        ? Math.floor(Number(this.$attrs.max) / this.step) * this.step
        : Number(this.$attrs.max);
    },
    isMaxInputLength () {
      return this.isDecimalMeasure && (`${this.qty}`).length === InputLength.MaxFloat;
    },
    disabledMax () {
      return this.INCREMENT > this.maxQty;
    },
    isMinusButtonShown () {
      return this.qty > this.minQty || this.isMicroCart || this.isCheckoutCartChanges;
    }
  },
  created () {
    this.inputValue = this.qty
  },
  methods: {
    ...mapActions({
      spawnNotification: 'notification/spawnNotification'
    }),
    qtyChangeHandler (qty, action) {
      let newQty = qty;
      if (qty < this.minQty) {
        newQty = this.minQty;
        this.gtmProductsHandler(this.product, 'remove_from_cart', {}, {
          value: true,
          calculate: true
        })
        this.$emit('remove');
      } else if (this.isDecimalMeasure) {
        newQty = roundNumberToPrecision(newQty, DIGITS_AFTER_COMMA);
      }

      if (action === 'plus') {
        this.gtmProductsHandler(this.product, 'add_to_cart', {}, {
          value: true,
          calculate: true
        })
        this.gtmProductsHandlerAds(this.product, 'add_to_cart_ads', true)
      }
      this.$emit('input', newQty);
    },
    inputQtyChangeHandler (inputValue) {
      const qty = Number(inputValue);

      if (inputValue !== '' && qty === 0) {
        this.gtmProductsHandler(this.product, 'remove_from_cart', {}, {
          value: true,
          calculate: true
        })
        this.$emit('remove');
        return;
      }

      if (inputValue.length === 0 || (this.qty === this.maxQty && qty > this.maxQty)) {
        if (qty > this.maxQty) this.showLimitNotification('maximum')
        this.$forceUpdate();
        return;
      }

      if (qty > this.maxQty) {
        this.showLimitNotification('maximum')
        this.$emit('input', this.maxQty);
        return;
      }

      if (qty < this.minQty || isNaN(qty)) {
        this.showLimitNotification('minimum')
        this.$emit('input', this.minQty);
        return;
      }

      if (this.isDecimalMeasure) {
        this.processDecimalQty(qty);
        return;
      }

      this.$emit('input', qty);
    },
    processDecimalQty (value) {
      const nearestValue = this.getNearestValidValue(value);
      this.$emit('input', roundNumberToPrecision(nearestValue, DIGITS_AFTER_COMMA));
    },
    getNearestValidValue (userInputValue) {
      const step = this.step;
      const minValue = this.minQty;

      let stepsFromMin = (userInputValue - minValue) / step;

      stepsFromMin = Math.round(stepsFromMin);
      return minValue + (stepsFromMin * step);
    },
    onInputKeydown (event) {
      if (CONTROL_CODES.includes(event.key)) return;

      let key = event.key;
      if (key === ',') key = '.';

      const el = event.target;

      const strArr = el.value.split('');
      strArr.splice(el.selectionStart, el.selectionEnd - el.selectionStart, key);
      const qty = strArr.join('');

      const regex = this.isQtyDecimal
        ? /^\d{1,3}([.]|[.]\d)?$/
        : /^\d{1,3}$/;

      if (!regex.test(qty)) {
        event.preventDefault();
        return;
      }

      if (event.key === ',') {
        event.preventDefault();
        el.value = qty;
      }
    },
    onInputFocus () {
      this.focused = true
      this.inputValue = this.qty
    },
    onInputBlur (event) {
      this.focused = false

      if (parseFloat(event.target.value) === parseFloat(this.qty)) {
        return
      }

      this.inputQtyChangeHandler(event.target.value)
    },
    showLimitNotification (type = '') {
      let message = ''

      if (type === 'minimum') {
        const minQty = roundNumberToPrecision(this.minQty, DIGITS_AFTER_COMMA)
        message = this.$t('Minimum quantity of orders {count} {measure}', { count: minQty, measure: this.measure })
      } else return

      this.spawnNotification({
        type: 'success',
        message,
        action1: { label: this.$t('OK') }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/components/atoms/SfQuantitySelector.scss";

.sf-quantity-selector__button {
  width: var(--spacer-56);
  height: var(--spacer-56);

  &:disabled {
    touch-action: none;
  }
}

.is-minus, .is-plus {
  background: var(--orange-light-background);

  &:disabled {
    opacity: .5;
  }
}

.sf-quantity-selector {
  ::v-deep .is-plus {
    &:not(:disabled):hover {
      background: var(--orange);

      .icon-plus {
        &:before {
          transition: all .3s ease;
          color: var(--white);
        }
      }
    }
  }
}

.sf-quantity-selector__input {
  ::v-deep .sf-input__wrapper {
    input[type="text"],
    input[type="number"] {
      outline: none;
      padding-right: var(--spacer-20);
      transition: background-color 200ms, border-bottom-color 200ms;
      border-bottom: 2px solid var(--orange-light-background);
    }

    input:focus {
      background-color: var(--white);
      border-bottom-color: var(--orange-hover);
    }

    .sf-input__label {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25px;
      right: 0;

      display: flex;
      align-items: center;
      justify-content: center;

      font-size: var(--font-size-13);
      color: var(--input-color, var(--c-text));

      &--hidden {
        display: none;
      }
    }
  }

  &--max-length {
    ::v-deep .sf-input__wrapper {
      input[type="text"] {
        padding-right: var(--spacer-16);
      }
    }
  }
}
</style>
